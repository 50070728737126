<template>
	<div>
		<main>
			<!-- breadcrumb-area -->
			<!-- <section class="breadcrumb-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-xl-8 col-md-6">
                <div class="breadcrumb-title sm-size">
                    <h2>About Us</h2>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 text-left text-md-right">
                <div class="breadcrumb">
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li class="text-white pl-1">About Us</li> 
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->

			<!-- about-area -->
			<section class="about-area pb-60 pt-100">
				<div class="container">
					<div class="row">
						<div class="col-xl-6 col-lg-6 mb-40">
							<div class="section-title">
								<h2>About Us</h2>
							</div>
							<div class="about-content">
								<h4 class="pt-10"><b>What is Extreme Solutions ?</b></h4>
								<h5 class="pt-10 pb-15"><b>A long history, in short</b></h5>
								<p>
									Based at Gandhidham, Extreme Solutions began its life as in
									2004, a small IT start-up, operated by Mr. Ketan Shukla as a
									one man show. As product portfolio began to grow, the business
									grew in demand and the funds started to flow, which resulted
									in a dedicated team committed to provide software solutions
									par excellence. Solutions, that are enduring, affordable and
									on time. <br />
									<br />We are Software developers. We develop customized and
									packaged software applications for various industry verticals
									like Manufacturing, Distribution and Trading, Retail, Cargo &
									transport on various technology platforms. We have expertise
									on Microsoft platform and embedded programming.
								</p>
							</div>
						</div>
						<div class="col-xl-6 col-lg-6 mb-20 text-center md-margin">
							<img src="assets/img/about/xtrm.jpg" class="img-fluid" alt="" />
						</div>
					</div>
				</div>
			</section>

			<section class="about-area grey-bg pb-30 pt-70">
				<div class="container">
					<div class="row">
						<div class="col-xl-9 col-lg-9 mb-40">
							<div class="section-title">
								<h2>Who we are ?</h2>
							</div>
							<div class="about-content">
								<h5 class="pb-15"><b>We are</b></h5>
								<p class="pb-15">
									<b
										><i class="fa fa-hand-o-right pr-10" aria-hidden="true"></i
										>Software developers.</b
									>
								</p>
								<p>
									We develop customized and packaged software applications for
									various industry verticals like Manufacturing, Distribution
									and Trading, Retail, Cargo & transport on various technology
									platforms. <br />
									<br />We have expertise on Microsoft platform and embedded
									programming. We have tie-ups with world majors and work on
									future technology. We have experience, skill and proven track
									record to undertake.
								</p>
								<br />

								<p>
									<b
										><i class="fa fa-hand-o-right pr-10" aria-hidden="true"></i
										>Enterprise-wide network & client-server applications
										development</b
									>
								</p>
								<p>
									<b
										><i class="fa fa-hand-o-right pr-10" aria-hidden="true"></i
										>Web Applications and services development</b
									>
								</p>
								<p>
									<b
										><i class="fa fa-hand-o-right pr-10" aria-hidden="true"></i
										>Software Product maintenance – pre and post implementation
										support</b
									>
								</p>
								<p>
									<b
										><i class="fa fa-hand-o-right pr-10" aria-hidden="true"></i
										>Software Product development – from concept to prototype
										and final product</b
									>
								</p>
								<br />
								<p>
									Manufacturers of standard and custom Kiosk enclosures. Turnkey
									kiosk solution providers. We develop information and
									transaction kiosks for specific requirements from concept to
									final product and implementation.
								</p>
							</div>
						</div>
						<div class="col-xl-3 col-lg-3 mb-40 pt-50">
							<div class="tag_line_weap">
								<div class="about_tag_line">
									<p>
										We tailor our service to fit your budget, paying only for
										what you need.
									</p>
									<p>
										And rest assured we will be exactly there, when you need us
									</p>
								</div>
								<div class="about_tag_line_btn text-center pt-20">
									<!-- <a href="/contact" class="domain-btn">Contact Us</a> -->
									<a href="javascript:void(0)" class="domain-btn" @click="$router.push('/contact')">Contact Us</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section class="about-area pb-40 pt-30">
				<div class="container">
					<div class="row">
						<div class="col-xl-12 col-lg-12 mb-40 pt-30">
							<div class="section-title">
								<h2>Whom we serve ?</h2>
							</div>
							<div class="about-content">
								<p>
									We have developed software solutions for Accounts, Finance,
									Administration, Stock & Inventories, Sales/Purchase, Order
									Processing, Point of sale, Investment, Interest calculation,
									Platform conversion, Porting and Migration, Intermediate
									applications and data conversion modules, Vertical
									Applications, ActiveX Components, Embedded systems and much
									more …
								</p>
								<br />
								<p>
									We have served virtually every business fraternity ranging
									from retailers/distributors to manufacturers, transport,
									logistics and shipping companies. Companies involved in real
									estate, hotel management, ware houses, imports and exports
									have immensely benefited from solutions provided by us.
								</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-xl-12 col-lg-12 mb-40 pt-30">
							<div class="section-title">
								<h2>How can we help you ?</h2>
							</div>
							<div class="about-content">
								<p>
									Extreme Solutions has expertise in a wide variety of the
									latest programming language tools and platforms for
									client-server application. Structured methodologies and proven
									IT processes helps to reduce complexity, risks and costs
									associated with client/server development.
								</p>
								<br />
								<p>
									We will help you develop customized software applications and
									assist you throughout the entire software development Life
									Cycle, including project management, systems analysis and
									design, software development, implementation and training.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="about-area pb-30 pt-30">
				<div class="container">
					<div class="row">
						<div class="col-xl-6 col-lg-6 mb-40">
							<div class="section-title">
								<h3>What packaged products we have ?</h3>
							</div>
							<div class="about-content">
								<p>
									For Information on our products, visit our Products Section.
								</p>
							</div>
							<div class="pt-30">
								<!-- <a href="/products" class="btn">Products</a> -->
								<a href="javascript:void(0)" class="btn" @click="$router.push('/products')">Products</a>
							</div>
						</div>

						<div class="col-xl-6 col-lg-6 mb-40">
							<div class="section-title">
								<h3>What services we offer ?</h3>
							</div>
							<div class="about-content">
								<p>
									For Information on our Services visit our Solutions Section.
								</p>
							</div>
							<div class="pt-30">
								<!-- <a href="/services" class="btn">Services</a> -->
								<a href="javascript:void(0)" class="btn" @click="$router.push('/services')">Services</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="service-area pb-70">
				<div class="container">
					<div class="row">
						<div class="col-xl-12 mb-40">
							<div class="section-title">
								<h2>Proof In The Pudding</h2>
								<p>
									Our credentials are more than skin deep. Here are selected
									ones to share with you…
								</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-lg-4 col-md-6 text-center mb-30">
							<div class="client_wrap">
								<img src="assets/img/clients/client-1.jpg" alt="FreightMan" />
								<br /><br />
								<p>
									India’s largest car manufacturing company requires no
									introduction. Their export facility based at mundra required a
									software system to manage inventory and perform audits.
								</p>
								<p class="pb-25">
									We provided them our product Stock Outlook to better manage
									their inventory with customized reports as per their own
									requirement.
								</p>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 text-center mb-30">
							<div class="client_wrap">
								<img src="assets/img/clients/client-2.jpg" alt="FreightMan" />
								<br /><br />
								<p>
									ANKUR CHEMFOOD LTD is world’s largest salt refinery founded in
									1994. It is engaged in manufacturing, marketing & exporting of
									Triple Refined Free Flow Iodised and Pure Industrial Salt.
								</p>
								<p class="pb-25">
									We provided them our product BizBank to better
									manageproduction, supply chain and sales with customized
									reports as per their own requirement.
								</p>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 text-center mb-30">
							<div class="client_wrap">
								<img src="assets/img/clients/client-3.jpg" alt="FreightMan" />
								<br /><br />
								<p>
									Westwind Shipping and Logistics Pvt. Ltd. is an international
									Freight Forwarder based at Mundra, India and operating at all
									other major ports of India offering optimum logistics services
									to business worldwide.
								</p>
								<p>
									We provided them our product EXIMax to better manage
									shipments, accounts and dues with customized reports as per
									their own requirement.
								</p>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 text-center mb-30">
							<div class="client_wrap">
								<img src="assets/img/clients/client-4.jpg" alt="FreightMan" />
								<br /><br />
								<p>
									V. Arjoon was founded in 1925 with the prime objective of
									providing logistic services to the shipping industry.
								</p>
								<p class="pb-25">
									We provided them our product Visual Shipment to better manage
									their export shipments.
								</p>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 text-center mb-30">
							<div class="client_wrap">
								<img src="assets/img/clients/client-5.jpg" alt="FreightMan" />
								<br /><br />
								<p>
									GUJARAT LOGISTICS was established with a motto to provide
									accurate and secure Transportation of valued cargos.
								</p>
								<p class="pb-25">
									We provided them our product FreightMan to better manage their
									transportation business.
								</p>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 text-center mb-30">
							<div class="client_wrap">
								<img src="assets/img/clients/client-6.jpg" alt="FreightMan" />
								<br /><br />
								<p>
									Agencies & Cargo Care Ltd is one of the leading edible oil
									storage handling terminal with rake loading facility through
									pipe line.
								</p>
								<p>
									We provided them our product Cargo Manager to better manage
									their cargo handling operations.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section class="service-area pb-70 pt-50">
				<div class="container">
					<div class="row">
						<div class="col-xl-12 mb-40">
							<div class="servicee-sidebar">
								<div class="sidebar-link grey-bg">
									<h3>The long and the short</h3>
									<div class="about-content pt-10" style="padding: 25px;">
										<p>If you would like to,</p>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-30"
												aria-hidden="true"
											></i
											>Invent on what really matters</P
										>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-30"
												aria-hidden="true"
											></i
											>Don’t let sloppy tools slow you down</P
										>

										<P
											><i
												class="fa fa-check-square-o pr-10 pt-30"
												aria-hidden="true"
											></i
											>Simplify your business</P
										>

										<p class="pt-20">
											Then you need to contact us. Remember, you are always
											invited.
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	</div>
</template>

<script>
export default {
	name: "About",
};
</script>

<style lang="scss" scoped></style>
